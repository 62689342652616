import java_logo from '../../src/images/java_logo.svg';
import spring_logo from '../../src/images/spring_logo.svg';
import jquery_logo from '../../src/images/jquery_logo.svg'
import react_logo from '../../src/images/react_logo.svg'
import redux_logo from '../../src/images/redux_logo.svg'
import angular_logo from '../../src/images/angularjs_logo.svg'
import html_logo from '../../src/images/html5_logo.svg'
import css_logo from '../../src/images/css3_logo.svg'
import javascript_logo from '../../src/images/javascript_logo.svg'
import bootstrap_logo from '../../src/images/bootstrap_logo.svg'
import material_ui_logo from '../../src/images/materialui-logo.svg'

import csharp_logo from '../../src/images/csharp_logol.svg'
import vs_logo from '../../src/images/visual_studio_logo.svg'
import maui_logo from '../../src/images/maui_logo.png'
import blazor_logo from '../../src/images/blazor_logo.png'
import unity_logo from '../../src/images/unity_logo.png';
import blender_logo from '../../src/images/blender_logo.svg';

import developer_logo from '../../src/images/developer_animation.png';
import github_logo from '../../src/images/github_logo.png';
import linkedin_logo from '../../src/images/linkedin_logo.png';
import google_play_logo from '../../src/images/google_play_logo.png';

import cattia_soft from '../../src/images/cattia_soft.png';
import linux_env from '../../src/images/linux_env.png';
import bac_romana from '../../src/images/bac_romana.jpg';
import app_monitor_software from '../../src/images/app_monitoring_software.png';
import bac_romana_android from '../../src/images/bac_romana_android.jpg';
import bac_fizica_android from '../../src/images/bac_fizica_android.jpg';
import mate_bac_android from '../../src/images/mate_bac_android.jpg';
import parcare_colina from '../../src/images/parcare_colina.png';
import map_gps_statistics from '../../src/images/map_gps_statistics.png';
import photo_map from '../../src/images/photo_map.png';
import speedometer from '../../src/images/speedometer.png';
import cars_game from '../../src/images/cars_game.png';

export const Bio = {
  name: "NXT64 Software",
  roles: [
    "Web Development",
    "Android Development",
    "Windows Development",
    "UI/UX Design",
  ],
  description:
    "NXT64 Software Project is a dynamic initiative specializing in Android, Web, and Windows Development. With a strong commitment to shaping the digital landscape through cutting-edge solutions, the project is dedicated to creating innovative software across diverse platforms.",
  github: "https://github.com/alexepure13/",
  google_play: "https://play.google.com/store/apps/developer?id=NeXT64+Software",
  linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
  insta: "https://www.instagram.com/nxt64.software/",
  facebook: "https://www.facebook.com/profile.php?id=100087288926527",
};

export const skills = [
  {
    title: "Web Development",
    skills: [
      {
        name: "Java",
        image: java_logo,
      },
         {
        name: "Spring Boot",
        image: spring_logo,
      },
      {
        name: "React Js",
        image: react_logo,
      },
      {
        name: "Redux",
        image: redux_logo,
      },
      {
        name: "Angular",
        image: angular_logo,
      },
      {
        name: "HTML",
        image: html_logo,
      },
      {
        name: "CSS",
        image: css_logo,
      },
      {
        name: "JavaScript",
        image: javascript_logo,
      },
        {
        name: "jQuery",
        image:
          jquery_logo,
      },
      {
        name: "Bootstrap",
        image: bootstrap_logo,
      },
      {
        name: "Material UI",
        image: material_ui_logo,
      },
    ],
  },
  {
    title: "Windows Development",
    skills: [
      {
        name: "Visual Studio",
        image: vs_logo,
      },
      {
        name: "C Sharp",
        image: csharp_logo,
      },
      {
        name: ".NET Maui",
        image: maui_logo,
      },
      {
        name: "Blazor",
        image: blazor_logo,
      },
      {
        name: "Unity",
        image: unity_logo,
      },
      {
        name: "Blender",
        image: blender_logo,
      },
    ],
  },
  {
    title: "Android Development",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "XML",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMw6_RdwKQ9bDFfnKDX1iwMl4bVJEvd9PP53XuIw&s",
      },
      {
        name: "Android Studio",
        image:
          "https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png",
      },
      {
        name: ".NET Maui",
        image: maui_logo,
      },
      {
        name: "Blazor",
        image: blazor_logo,
      },
    ],
  },
];


export const projects = [
  {
    id: 0,
    title: "Cattia Soft",
    date: "May 2022 - Sep 2022",
    description:
      "Cattia Soft is a web platform crafted using Java and Spring Boot. This robust platform is dedicated to the efficient management of Ukrainian refugees, streamlining stock inventory control, and ensuring seamless personal data management. With a focus on formality and precision, Cattia Soft offers comprehensive tools and features to facilitate the management of these critical aspects, contributing to enhanced organization, transparency, and effectiveness in related operations.",
    image: cattia_soft,
    tags: ["Java", "Spring Boot", "JPA", "Thymeleaf", "HTML", "CSS", "JavaScript", "Bootstrap", "jQuery", "MySQL", "Liquibase"],
    category: "web app",
    github: "https://github.com/alexepure13/cattia-soft",
    webapp : "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 1,
    title: "Linux Env.",
    date: "Aug 2022 - Nov 2022",
    description:
      "Linux Env. is a web platform built with Spring Boot, crafted to replicate a Linux Operating System environment. This feature-rich platform provides users with an authentic Linux terminal experience, enabling the execution of various commands and operations.\nIn addition to the terminal, Linux Env. keeps users updated with the latest operating system news, offers an extensive historical archive, and provides a comprehensive wiki for reference. One of its standout features is the user-friendly admin panel, which empowers administrators to effortlessly add, modify, or delete news articles, wiki content, and terminal commands. This level of control ensures that Linux Env. remains a dynamic and informative resource, catering to Linux enthusiasts, administrators, and those seeking to expand their knowledge of this powerful operating system.",
    image: linux_env,
    tags: ["Java", "Spring Boot", "JPA", "Web Socket", "Thymeleaf", "HTML", "CSS", "JavaScript", "Bootstrap", "jQuery", "MySQL"],
    category: "web app",
    github: "https://github.com/alexepure13/linux-environment",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
    webapp: "null",
  },
  {
    id: 2,
    title: "bacromana.ro",
    date: "Nov 2017 - present",
    description:
      "'bacromana.ro' provides comprehensive Baccalaureate Exam preparation resources for 'Limba si Literatura Română'.\nThis platform is designed to help you excel in your BAC examination, and it is thoughtfully organized for easy navigation, ensuring that learners of all levels can quickly access the resources they need.\nHere's what you'll find on bacromana.ro:\n\nSubiectul III: Delve into in-depth commentary on Romanian literature, with a focus on Subject III of the Baccalaureate Exam. Gain a deeper understanding of literary analysis and interpretation.\nCurente literare: Explore the rich tapestry of literary movements in Romanian literature. This section provides valuable insights into the various literary periods and their significance.\nEseuri argumentative + tehnici: Hone your essay writing skills with a collection of argumentative essays and valuable writing techniques. Prepare to craft compelling and structured arguments.\nSubiectul al II-lea, Noțiuni teoretice: Dive into the theoretical aspects of Romanian language and literature, a crucial component of the Baccalaureate Exam. Enhance your knowledge of the subject.\nModele de subiecte: Practice makes perfect. Access a repository of sample exam papers to test your knowledge and readiness for the BAC examination.",
    image: bac_romana,
    tags: ["HTML", "CSS", "JavaScript", "Bootstrap", "jQuery", "MySQL"],
    category: "web app",
    github: "null",
    webapp: "https://bacromana.ro/",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 3,
    title: "App Monitoring Software",
    date: "Oct 2022 - July 2023",
    description: "'App Monitoring Software', developed for the Windows platform using .NET Maui Blazor, is a comprehensive solution for efficient monitoring of applications on both desktop and mobile devices.\nWith a strong emphasis on versatility, this application offers the following key features:Core Features:\n\nHardware Resource Monitoring: Gain detailed insights into the hardware resources utilized by specific applications, allowing you to track and optimize their performance effectively.\nMulti-Application Monitoring: Simultaneously manage the monitoring of multiple applications, facilitating efficient resource and performance management.\nNetwork Connection Tracking: Examine connections made by the monitored application with external sources for enhanced security and connectivity management.\nError and Crash Detection: Receive prompt alerts in the event of application errors or crashes, ensuring your ability to intervene swiftly.\nDetailed Reports: Access in-depth reports that provide clear insights into performance and resource usage.\nVersion Hierarchy: Organize and categorize reports and data based on the version of the monitored application, simplifying analysis and evaluation.\nProcess Dashboard: Get an informative dashboard presenting the currently running processes on your device.\nHardware Resource Dashboard: Monitor the hardware resource usage of all processes, including the operating system, for more efficient resource management.\nRemote Computer Service: Implement a service that can be installed on a remote computer, allowing you to connect via WCF and monitor processes running on that device. In case of issues, receive alerts on the main device or via email, as per your preferences.\nRemote Device Installer: Implement an installer for deploying the service on remote devices and the desktop application.",
    image: app_monitor_software,
    tags: [".NET Maui", "Blazor", "C#", "Razor", "HWiNFO", "Bootstrap", "Radzen Blazor", "Syncfusion", "MS SQL Server"],
    category: "win app",
    github: "null",
    webapp: "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 10,
    title: "Comentarii BAC Română",
    date: "Sep 2017 - present",
    description:
    "'Comentarii BAC Română' provides comprehensive Baccalaureate Exam preparation resources for 'Limba si Literatura Română'.\nHere's what you'll find on Comentarii BAC Română:\n\nSubiectul III: Delve into in-depth commentary on Romanian literature, with a focus on Subject III of the Baccalaureate Exam. Gain a deeper understanding of literary analysis and interpretation.\nCurente literare: Explore the rich tapestry of literary movements in Romanian literature. This section provides valuable insights into the various literary periods and their significance.\nEseuri argumentative + tehnici: Hone your essay writing skills with a collection of argumentative essays and valuable writing techniques. Prepare to craft compelling and structured arguments.\nSubiectul al II-lea, Noțiuni teoretice: Dive into the theoretical aspects of Romanian language and literature, a crucial component of the Baccalaureate Exam. Enhance your knowledge of the subject.\nModele de subiecte: Practice makes perfect. Access a repository of sample exam papers to test your knowledge and readiness for the BAC examination.",
    image: bac_romana_android,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "ComPDFKit"],
    category: "android app",
    github: "null",
    webapp: "https://play.google.com/store/apps/details?id=com.acitexsoftware.bacromana",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 4,
    title: "Fizică BAC - Teorie și Aplicații",
    date: "Jun 2019 - present",
    description:"'Fizica BAC - Teorie și Aplicații' is an Android app for comprehensive preparation in all areas of Physics required for the Baccalaureate examination.\nThis user-friendly app covers the following core subjects:\n\nMECHANICS - Theory and Applications\nELECTRICITY - Theory and Applications\nTHERMODYNAMICS - Theory and Applications\nOPTICS - Theory and Applications",
    image: bac_fizica_android,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "ComPDFKit"],
    category: "android app",
    github: "null",
    webapp: "https://play.google.com/store/apps/details?id=com.acitexsoftware.fizicabac",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 5,
    title: "Mate Bac - Teorie si Aplicatii",
    date: "Jun 2021 - present",
    description: "'Mate BAC - Teorie și Aplicații' is an Android app designed to provide comprehensive preparation for the Baccalaureate examination. This app is structured to assist you in your BAC preparations and ensure that you can easily access the content you need.\nHere's what you'll find in this app:\n\nAlgebra - Theory\nMathematical Analysis - Theory\nGeometry and Trigonometry - Theory",
    image: mate_bac_android,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "ComPDFKit"],
    category: "android app",
    github: "null",
    webapp: "https://play.google.com/store/apps/details?id=com.acitexsoftware.matebac",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 6,
    title: "Colina Parking",
    date: "Dec 2020 - Feb 2021",
    description: "'Colina Parking', an innovative parking app, was developed with the aim of simplifying the parking process and reducing the challenges associated with finding a parking spot. The app features a user-friendly login system for easy access. Upon logging in, users can explore an intuitive menu that provides all the necessary tools and information for a hassle-free parking experience.\nThe app's administration and control are facilitated through the integrated Admin Panel, ensuring efficient management. Colina Parking offers a practical solution for drivers looking to enhance their parking experience.",
    image: parcare_colina,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "Firebase", "Firebase Auth"],
    category: "android app",
    github: "https://github.com/alexepure13/parcare-colina",
    webapp: "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 7,
    title: "Map, GPS and Statistics",
    date: "Sep 2022 - Dec 2022",
    description: "'Map, GPS, and Statistics' is an Android app that serves as your essential companion for location tracking, navigation, and data analysis. This app combines powerful features to offer a comprehensive solution for various needs.\nKey functionalities include:\n\nMapping: Access detailed maps to explore locations, plan routes, and get directions with ease.\nGPS Tracking: Use the built-in GPS functionality to track your current location and navigate to your destination efficiently.\nStatistics: Gain valuable insights into your journey with in-depth statistical analysis. Monitor distance traveled, speed, and other metrics.",
    image: map_gps_statistics,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "Firebase", "Firebase Auth"],
    category: "android app",
    github: "null",
    webapp: "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 8,
    title: "Photo Map",
    date: "Sep 2022 - Dec 2022",
    description: "'Photo Map' is a practical Android app designed to enhance your photography experience. With its primary features focused on location-based photo organization and image viewing, this app simplifies the way you capture and revisit your memories.\nKey functionalities include:\n\nLocation-Based Photo Capture: Snap photos and have them automatically pinned to a map, marking the exact spot where each photo was taken.\nInteractive Map: Explore your photo collection on an interactive map, allowing you to visualize and organize your memories based on location.\nImage Viewer: Effortlessly view and relive your photos with a user-friendly image viewer.",
    image: photo_map,
    tags: ["Android Studio", "XML", "Java", "Kotlin", "Firebase", "Firebase Auth"],
    category: "android app",
    github: "null",
    webapp: "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 9,
    title: "Speedometer",
    date: "Jul 2021 - Aug 2021",
    description: "The 'Speedometer' app for Android is a practical tool that brings real-time speed monitoring. This user-friendly app offers essential features for tracking and managing your speed while on the move.\nKey functionalities include:\n\nReal-Time Speed Display: Get accurate, real-time speed readings right on your smartphone's screen, eliminating the need for additional equipment.\nSpeed Limit Alerts: Set custom speed limits and receive alerts to ensure you stay within legal speed limits, enhancing safety on the road. Trip Statistics:\nAccess detailed statistics about your journeys, including maximum speed, average speed, and trip duration.",
    image: speedometer,
    tags: ["Android Studio", "XML", "Java", "Kotlin"],
    category: "android app",
    github: "null",
    webapp: "null",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
  {
    id: 11,
    title: "CARS - Mud, Wind and Rain",
    date: "Feb 2022 - Jun 2022",
    description: "The project theme involves creating a Unity 3D car racing game. The game features two modes of play. The first mode is the competitive mode, where the main player competes against 2 opponents. For this mode, there are 2 tracks available, one set during the day and the other at night.\nThe second game mode is the Timer mode, where the main player must complete a race within a specified time limit for each track. In this mode, there are 3 tracks available, named in the game as Sunset, Mountains, and Circuit.",
    image: cars_game,
    tags: ["Unity", "Blender", "EasyRoads3D"],
    category: "win app",
    github: "null",
    webapp: "https://drive.google.com/file/d/18HCQwBUnpYtUkX8DXbRau1yvQWedcAu6/view?usp=sharing",
    member: [
      {
        name: "Alexandru Epure",
        img: developer_logo,
        linkedin: "https://www.linkedin.com/in/alexandru-epure-3aa6aa151/",
        github: "https://github.com/alexepure13/",
      },
    ],
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
